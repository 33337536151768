import React from 'react'
import './AsyncLoader.css'

type AsyncLoaderProps = {
  className?: string,
  color?: string,
  testId?: string
}

const AsyncLoader = (props: AsyncLoaderProps) => {
  let { className, color, testId, ...otherProps } = props;

  return (
    <div className="AsyncLoader" {...otherProps} data-test-id={testId}>
      <div className="AsyncLoader--dot" style={{ backgroundColor: color }}></div>
      <div className="AsyncLoader--dot" style={{ backgroundColor: color }}></div>
      <div className="AsyncLoader--dot" style={{ backgroundColor: color }}></div>
    </div>
  )
}
AsyncLoader.defaultProps = { testId: 'cs-async-loader', color: "#fff" } as Partial<AsyncLoaderProps>;

export default AsyncLoader