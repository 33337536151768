import React, { useState } from 'react';
import copy from 'copy-to-clipboard';

import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';

import './ClipBoard.css';

export type clipBoardProps = {
  children?: React.ReactNode
  copyText: string
  copyLabel?: string
  className?: string
  displayText?: string
  position?: string
  type?: string
  withArrow?: boolean
  testId?: string
};

type clipFunction = {
  copyText: string
  onCopy: Function
};

export const csClipBoard = ({ copyText, onCopy }: clipFunction) => {
  copy(copyText, {
    onCopy: onCopy()
  });
};

const ClipBoard = (props: clipBoardProps) => {
  const [copyStat, setCopyStat] = useState(false);
  const withArrow = props.withArrow;
  const position = props.position;
  const copyLabel = props.copyLabel;

  const handleClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault()
    copy(props.copyText);
    setCopyStat(true);
  };

  const handleReset = () => {
    setCopyStat(false);
  };

  const displayComponent = () => {
    if (props.children === undefined) {
      switch (props.type) {
        case 'default':
          return (
            <>
              <Tooltip
                content={copyStat ? 'Copied' : `${copyLabel === null ? 'Copy' : copyLabel}`}
                showArrow={withArrow}
                position={position}
                onHidden={handleReset}
                trigger={copyStat ? 'mouseenter' : 'click mouseenter'}
              >
                <div data-test-id={props.testId} className="ClipBoard" onClick={handleClick}>
                  {props.displayText}
                </div>
              </Tooltip>
            </>
          );
        case 'button':
          return (
            <>
              <Tooltip
                content={copyStat ? 'Copied' : `${copyLabel === null ? 'Copy' : copyLabel}`}
                showArrow={withArrow}
                position={position}
                onHidden={handleReset}
                trigger={copyStat ? 'mouseenter' : 'click mouseenter'}
              >
                <Button onClick={handleClick}>{props.displayText}</Button>
              </Tooltip>
            </>
          );
        default:
          break;
      };
    } else if (props.children !== undefined) {
      return (
        <>
          <Tooltip
            content={copyStat ? 'Copied' : `${copyLabel === null ? 'Copy' : copyLabel}`}
            showArrow={withArrow}
            position={position}
            onHidden={handleReset}
            trigger={copyStat ? 'mouseenter' : 'click mouseenter focusin'}
          >
            <div
              data-test-id={props.testId}
              className="ClipBoard"
              tabIndex={0}
              onFocus={(e) => {
                const mouseoverEvent = new Event('mouseover');
                e.target.dispatchEvent(mouseoverEvent);
              }}
              onBlur={() => {
                handleReset()
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick(e)
                }
              }}
              onClick={handleClick}>{props.children}</div>
          </Tooltip>
        </>
      );
    };
  };

  return <>{displayComponent()}</>
};

ClipBoard.defaultProps = {
  position: 'right',
  copyLabel: null,
  withArrow: false,
  type: 'default',
  testId: 'cs-clipboard'
} as Partial<clipBoardProps>;

export default ClipBoard
