//Different time zones and selector
import React, {
  useEffect,
  useState
} from 'react'

import { defaultTimezone } from '../DateTime/helper'
import { ITimeZoneStandard } from '../DateTime/interfaces'
import DSTTimeZoneList from '../DateTime/TimeZoneList'
import Dropdown from '../DropDown/Dropdown'

const TimeZoneStandard: React.FunctionComponent<ITimeZoneStandard> = props => {
  //props.date from props date comes
  const { zone, onChange, dstZone, disableTzDropdown } = props
  const propsZone = dstZone


  let DSTTZList = DSTTimeZoneList();
  let defaultTZ = (zone) ? defaultTimezone(zone) : defaultTimezone('');
  let zoneObj: any;

  console.log("aaaaaa ", dstZone, DSTTimeZoneList());

  if (dstZone) {
    let idx = DSTTZList.findIndex(singleTZObj => singleTZObj.value === dstZone);
    if (typeof (idx) !== 'undefined' && DSTTZList[idx]) {
      DSTTZList[idx].default = true;
    }
    zoneObj = DSTTZList[idx];
  } else {
    let idx = DSTTZList.findIndex(singleTZObj => singleTZObj.value === defaultTZ);
    if (typeof (idx) !== 'undefined' && DSTTZList[idx]) {
      DSTTZList[idx].default = true;
    }
    zoneObj = DSTTZList[idx];
  }
  const [currZoneObj, setCurrZoneObj] = useState(zoneObj);

  const isZoneValid = (value: any) => {
    let val = value.includes('+') ? value.split('+')[1] : value.split('-')[1]
    return val.length === 4
  }

  const zoneHandler = (value: any) => {
    console.log("zoneHandler ", value);
    let valObj = { ...value } //cloneDeep(value);
    if (!valObj) {
      valObj = {};
    }
    let val: any = (valObj.value) ? valObj.value : '';
    onChange(val, valObj);
  }

  useEffect(() => {
    if (dstZone) {
      let idx = DSTTZList.findIndex(singleTZObj => singleTZObj.value === dstZone);
      if (typeof (idx) !== 'undefined' && DSTTZList[idx]) {
        DSTTZList[idx].default = true;
      }
      setCurrZoneObj(DSTTZList[idx]);
      zoneHandler({ ...DSTTZList[idx] });
    } else {
      let idx = DSTTZList.findIndex(singleTZObj => singleTZObj.value === defaultTZ);
      if (typeof (idx) !== 'undefined' && DSTTZList[idx]) {
        DSTTZList[idx].default = true;
      }
      setCurrZoneObj(DSTTZList[idx]);
      zoneHandler({ ...DSTTZList[idx] });
    }
  }, [propsZone])

  return (
    <div>
      {/* <Select
        options={ZoneOptions(zone)}
        // isClearable={true}
        isSearchable={false}
        defaultValue={zoneValue}
        value={zoneValue}
        onChange={event => zoneHandler(event)}
      /> */}
      <Dropdown
        list={DSTTZList}
        type="select"
        viewAs="label"
        title="Choose Time Zone"
        searchPlaceholder="search time zone"
        dropDownType="tertiary"
        withSearch={true}
        onChange={zoneHandler}
        closeAfterSelect={true}
        arrowSecondary={true}
        isDisabled={disableTzDropdown}
      />
    </div>
  )
}

export default TimeZoneStandard
