import './Dropdown.css'

import React, {
  useEffect,
  useRef,
  useState
} from 'react'

import cn from 'classnames'
import {
  DragDropContext,
  Draggable,
  Droppable
} from 'react-beautiful-dnd'

import ActionTooltip, { ActionTooltipList } from '../ActionTooltip/ActionTooltip'
import SearchPortal from '../AdvanceSearch/SearchPortal'
import Icon from '../Icon2/Icon'
import TextInput from '../TextInput/TextInput'

export type IList = {
  label: string | React.ReactNode
  action?: Function
  default?: boolean
  searchKey?: string
  value?: string | React.ReactNode | Element
  textLabel?: string //required only when label is html
  disable?: boolean
  closeDropdownOnSelect?: boolean
  actionTooltip?: boolean
  actionTooltipProps?: { list: Array<ActionTooltipList> },
  allowMultipleSelects?: boolean
}

type IDropdown = {
  list: Array<IList>
  type: 'click' | 'hover' | 'select'
  children?: React.ReactNode | Element
  withSearch?: boolean
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  className?: string
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  closeAfterSelect?: boolean
  isEllipse?: boolean
  isMultiCheck?: boolean
  title?: string
  searchPlaceholder?: string
  onChange?: (data?: any) => void
  viewAs?: 'label' | 'value'
  highlightActive?: boolean
  headerLabel?: string
  arrowSecondary?: boolean
  emptyPlaceholder?: string
  adjustWidthForContent?: boolean
  withIcon?: boolean
  maxWidth?: string | number
  testId?: string
  onListScroll?: any
  portalId?: string
  withPortal?: boolean
  headerPortalId?: string
  ellipseAlignment?: string
  dragDropProps?: Object
  isDisabled?: boolean
}

type IDropdownOption = {
  list: Array<IList>
  children?: React.ReactNode | Element
  withArrow?: boolean
  dropDownType?: 'primary' | 'secondary' | 'tertiary'
  classNames?: string
  closeAfterSelect?: boolean
  isEllipse?: boolean
  isMultiCheck?: boolean
  title?: string
  arrowSecondary?: boolean
  adjustWidthForContent?: boolean
  withIcon?: boolean
  maxWidth?: string | number
  onListScroll?: any
  testId?: string
  viewAs?: 'label' | 'value'
  onChange?: (data?: any) => void
  portalId?: string
  withPortal?: boolean
  headerPortalId?: string
  highlightActive?: boolean
  ellipseAlignment?: string
  dragDropProps?: Object
  isDisabled?: boolean
}

type ISelectDropdown = {
  list: Array<IList>
  withSearch?: boolean
  classNames?: string
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  closeAfterSelect?: boolean
  title?: string
  searchPlaceholder?: string
  onChange?: (data?: any) => void
  viewAs: 'label' | 'value'
  highlightActive?: boolean
  headerLabel?: string
  arrowSecondary?: boolean
  emptyPlaceholder?: string
  adjustWidthForContent?: boolean
  withIcon?: boolean
  maxWidth?: string | number
  onListScroll?: any
  testId?: string
  portalId?: string
  withPortal?: boolean
  headerPortalId?: string
  isDisabled?: boolean
}

const DraggableComponent = (props) => {
  const item = props.item
  return (
    <Draggable draggableId={item.id} index={props.index} key={item.id} isDragDisabled={props.isDragDisabled}>
      {(provided: any, _snapshot: any) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {props.children}
        </div>
      )}
    </Draggable>
  )
}

const DroppableComponent = (props) => {
  return (
    <DragDropContext onDragEnd={props.onDragEnd} onDragStart={props.onDragStart}>
      <Droppable droppableId="droppable-test" direction="vertical">
        {(provided: any, _snapshot: any) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props.children(provided)}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref: any = useRef(null)
  const headerRef: any = useRef(null)
  const headerEleRef: any = useRef(null)

  const menuRef: any = useRef(null)
  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    }
  }

  const handleClickOutside = (event: any) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      if (
        (ref.current && ref.current.contains(event.target)) ||
        document.activeElement === document.getElementById('sidebar-extension') ||
        document.activeElement.clientWidth == 315
      ) {
        return
      } else {
        setIsComponentVisible(false)
      }
    }
    if (headerEleRef.current && headerEleRef.current.contains(event.target)) {
      return
    }
    if (
      (ref.current && !ref.current.contains(event.target)) ||
      document.activeElement === document.getElementById('sidebar-extension') ||
      document.activeElement.clientWidth == 315
    ) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, menuRef, headerRef, headerEleRef, isComponentVisible, setIsComponentVisible }
}

const ClickableDropDown: React.FunctionComponent<IDropdownOption> = ({
  list,
  children,
  dropDownType,
  withArrow,
  arrowSecondary,
  title,
  withIcon,
  classNames,
  closeAfterSelect,
  isEllipse,
  isMultiCheck,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
  testId,
  viewAs,
  onChange,
  highlightActive,
  ellipseAlignment,
  dragDropProps,
  isDisabled
}) => {
  const { canDragAndDrop = false, onDragStart, onDragEnd }: any = dragDropProps
  let isDragging = false

  const getDefaultValue = (_list: any) => {
    let default_element: any
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    _list.map((list_element: any) => {
      if (list_element.hasOwnProperty('default')) {
        if (list_element.default) {
          default_element = list_element
        }
      }
    })
    if (default_element) {
      if (default_element[viewAs]) {
        return default_element[viewAs]
      } else {
        return default_element.label ? default_element.label : default_element.value
      }
    }
  }
  const [selectedData, setSelectedData] = useState(getDefaultValue(list))

  const { ref, menuRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  let checkboxLabel: any = `Dropdown__menu__list ${isMultiCheck ? 'Dropdown__menu__list__checkbox' : ''}`

  const handleSelect = (itemAction: any, index: any, itemObj?: any) => {
    if (highlightActive) {
      if (list[index][viewAs]) {
        if (list[index][viewAs] !== selectedData) {
          setSelectedData(list[index][viewAs])
          itemAction()
        }
      }
    } else {
      itemAction()
    }
    if (onChange !== undefined) {
      onChange(list[index])
    }
    const { closeDropdownOnSelect } = itemObj || {}
    if (closeAfterSelect || closeDropdownOnSelect) {
      setIsComponentVisible(!isComponentVisible)
    }
  }
  useEffect(() => {
    setSelectedData(getDefaultValue(list))
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  useEffect(() => {
    if (isComponentVisible) {
      const isInside = () => {
        if (menuRef.current) {
          const rect = menuRef.current.getBoundingClientRect()
          const windowHeight = window.innerHeight
          const windowWidth = window.innerWidth
          const isInsideHeight = rect.top >= 0 && rect.bottom <= windowHeight
          const isInsideWidth = rect.left >= 0 && rect.right <= windowWidth
          return {
            inside: isInsideHeight && isInsideWidth,
            diff: {
              bottom: windowHeight - rect.bottom,
              right: windowWidth - rect.right
            }
          }
        }
        return {
          inside: false,
          diffe: {}
        }
      }
      const { inside, diff } = isInside()
      if (!inside) {
        if (diff.bottom < 0) {
          menuRef.current.style.top = `${menuRef.current.offsetTop + diff.bottom}px`
        }
        if (diff.right < 0) {
          menuRef.current.style.left = `${menuRef.current.offsetLeft + diff.right}px`
        }
      }
    }
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible])

  const handleDragStart = () => {
    isDragging = true
    if (onDragStart) {
      onDragStart()
    }
  }

  const getDropdownMenuActiveClass = (item, _viewAs, _selectedData) => {
    return item[_viewAs] === _selectedData
      ? 'Dropdown__menu__list__item--active'
      : '';
  }
  const getdDropdownLabelActiveClass = (item, _selectedData, _highlightActive) => {
    return item.label === _selectedData && _highlightActive
      ? 'Dropdown__menu__list__item--active'
      : '';
  }
  const handleDragEnd = (result, draggableId) => {
    if (onDragEnd) {
      onDragEnd(result, draggableId)
    }
    isDragging = false
  }
  const dropdownStyle = dropDownType === 'tertiary' ? 'Dropdown__menu--tertiary' : 'Dropdown__menu--primary'
  const dropdownHeaderEllipsis = isEllipse ? `Dropdown__header--ellipses ${ellipseAlignment}` : '';
  const toggleIcon = isComponentVisible === true ? 'toggle-icon' : '';

  const handleClickDropdown = (evt, index, item) => {
    evt.stopPropagation()
    if (!item.disable) {
      handleSelect(item.action ? item.action : () => { /* TODO document why this arrow function is empty */ }, index, item)
    }
  }
  const iconType = arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall';
  return (
    <div
      data-test-id={testId}
      className={cn(
        classNames,
        {
          'disable__dropdown': isDisabled,
          'Dropdown--open': isComponentVisible
        }
      )}
      ref={ref}
      onClick={(evt: any) => {
        if (!isDragging && !isDisabled) {
          evt.stopPropagation()
          setIsComponentVisible(!isComponentVisible)
        }
      }}>
      <div className={`${'Dropdown__header'} ${dropdownHeaderEllipsis}`}>
        {isEllipse ? (
          <>
            <Icon icon="SeeMore" size="original" />
          </>
        ) : (
          <>
            {children}
            {withArrow && (
              <Icon
                className={`ml-5 Dropdown__chevron ${toggleIcon}`}
                icon={iconType}
                size="original"
              />
            )}
          </>
        )}
      </div>
      {isComponentVisible && (
        <div
          ref={menuRef}
          className={dropDownType === 'secondary' ? 'Dropdown__menu--secondary' : dropdownStyle}
          style={{
            ...(adjustWidthForContent && { width: 'min-content' }),
            ...(maxWidth && { maxWidth: maxWidth, minWidth: 0 })
          }}>
          {title ? <div className="Dropdown__menu_title">{title}</div> : null}

          {canDragAndDrop ? (
            <ul className={checkboxLabel} onScroll={onListScroll}>
              <DroppableComponent onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                {(provided) => (
                  <>
                    {list.map((item: any, index: any) => {

                      if (item.canDragDrop) {
                        return (
                          <DraggableComponent
                            item={item}
                            key={item.id}
                            index={index}
                            isDragDisabled={!item.canDragDrop}>
                            <li
                              className={`Dropdown__menu__list__item flex-v-center Dropdown__menu__list__item--dragging ${item.disable ? 'Dropdown__menu__list__item--disable' : ''
                                } ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} ${item[viewAs] && highlightActive
                                  ? getDropdownMenuActiveClass(item, viewAs, selectedData)
                                  : getdDropdownLabelActiveClass(item, selectedData, highlightActive)

                                }  `}
                              onClick={(evt) => handleClickDropdown(evt, index, item)}>
                              <div className="pl-10">
                                <Icon icon="MoveIcon" size="extraSmall" />
                              </div>
                              {item.label}
                            </li>
                          </DraggableComponent>
                        )
                      }
                      return (
                        <li
                          className={`Dropdown__menu__list__item flex-v-center ${item.disable ? 'Dropdown__menu__list__item--disable' : ''
                            } ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} ${item[viewAs] && highlightActive
                              ? getDropdownMenuActiveClass(item, viewAs, selectedData)
                              : getdDropdownLabelActiveClass(item, selectedData, highlightActive)
                            }`}
                          onClick={(evt) => handleClickDropdown(evt, index, item)}
                          key={item.id}>
                          <div className="pl-10" style={{ visibility: 'hidden' }}>
                            <Icon icon="MoveIcon" size="extraSmall" />
                          </div>
                          {item.label}
                        </li>
                      )
                    })}
                    {provided.placeholder}
                  </>
                )}
              </DroppableComponent>
            </ul>
          ) : (
            <ul className={checkboxLabel} onScroll={onListScroll}>
              {list.map((item: any, index: any) => {
                return (
                  <li
                    key={index}
                    className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''
                      } ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} ${item[viewAs] && highlightActive
                        ? getDropdownMenuActiveClass(item, viewAs, selectedData)
                        : getdDropdownLabelActiveClass(item, selectedData, highlightActive)
                      }`}
                    onClick={(evt) => handleClickDropdown(evt, index, item)}>
                    {item.label}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

const HoverDropDown: React.FunctionComponent<IDropdownOption> = ({
  list,
  children,
  withIcon,
  dropDownType,
  title,
  withArrow,
  arrowSecondary,
  classNames,
  closeAfterSelect,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
  testId,
  isDisabled
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const dropdownStyle = dropDownType === 'tertiary' ? 'Dropdown__menu--tertiary' : 'Dropdown__menu--primary'
  return (
    <div
      data-test-id={testId}
      ref={ref}
      className={cn(
        `Dropdown--hover ${classNames}`,
        {
          'disable__dropdown': isDisabled,
          'Dropdown--open': isComponentVisible
        }
      )}
      onMouseEnter={() => {
        if (!isDisabled) {
          setIsComponentVisible(true)
        }
      }}
      onMouseLeave={() => {
        setIsComponentVisible(false)
      }}>
      <div className="Dropdown__header">
        {children}
        {withArrow && (
          <Icon
            className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
            icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
            size="original"
          />
        )}
      </div>
      {isComponentVisible && (
        <div
          className={dropDownType === 'secondary' ? 'Dropdown__menu--secondary' : dropdownStyle}
          style={{
            ...(adjustWidthForContent && { width: 'min-content' }),
            ...(maxWidth && { maxWidth: maxWidth, minWidth: 0 })
          }}>
          {title ? <div className="Dropdown__menu_title">{title}</div> : null}
          <ul className="Dropdown__menu__list" onScroll={onListScroll}>
            {list.map((item: any, i: any) => {
              return (
                <li
                  key={i}
                  className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${withIcon ? 'Dropdown__menu__list__item-icon' : ''
                    }`}
                  onClick={() => {
                    if (!item.disable) {
                      if (item.action) {
                        item.action()
                      }
                      const { closeDropdownOnSelect } = item || {}
                      if (closeAfterSelect || closeDropdownOnSelect) {
                        setIsComponentVisible(!isComponentVisible)
                      }
                    }
                  }}>
                  {item.label}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

const SelectDropDown: React.FunctionComponent<ISelectDropdown> = ({
  list,
  withSearch,
  arrowSecondary,
  withIcon,
  emptyPlaceholder,
  classNames,
  dropDownType,
  title,
  searchPlaceholder,
  closeAfterSelect,
  onChange,
  viewAs,
  highlightActive,
  headerLabel,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
  testId,
  withPortal,
  portalId,
  headerPortalId,
  isDisabled
}) => {
  const getDefaultValue = (_list: any) => {
    let default_element: any
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    default_element = getDefaultElement(_list);
    if (default_element) {
      if (default_element[viewAs]) {
        return default_element[viewAs]
      } else {
        return default_element.label ? default_element.label : default_element.value
      }
    }
  }

  const getDefaultElement = (_list: any) => {
    let default_element: any
    _list.map((list_element: any) => {
      if (list_element.hasOwnProperty('default')) {
        if (list_element.default) {
          default_element = list_element;
        }
      }
    })
    return default_element;
  }

  const getDefaultLabelText = (_list: any) => {
    let default_element: any
    default_element = getDefaultElement(_list);

    if (default_element) {
      if (default_element[viewAs]) {
        return default_element.textLabel || default_element[viewAs]
      } else {
        return default_element.label ? default_element.textLabel || default_element.label : default_element.value
      }
    }
  }

  const [searchText, setSearchText] = useState('')
  const [selectedData, setSelectedData] = useState(getDefaultValue(list))
  const [selectedDataText, setSelectedDataText] = useState(getDefaultLabelText(list))
  const [searchList, setSearchList] = useState([])
  const { ref, headerRef, headerEleRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const [dropdownId, setDropdownId] = useState(false)
  const [dropdownKey] = useState(Math.random().toString(16).slice(-4))

  const handleChange = (evt: any) => {
    if (evt.target.value.length > 0) {
      let newSearchList: any = []
      list.forEach((el: any) => {
        let searchKeyList = ['value', 'searchKey']
        if (typeof el.label === 'string') {
          searchKeyList.pop()
          searchKeyList.push('label')
        }
        let flag: boolean = false
        searchKeyList.forEach((search_el: any) => {
          if (!flag && el[search_el] && el[search_el].toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
            flag = true
          }
        })
      })
      setSearchList(newSearchList)
    }
    setSearchText(evt.target.value)
  }

  const handleSelect = (itemAction: any, index: any, itemObj?: any) => {
    let cbValue: any
    if (searchText.length > 0) {
      let chkSearchList: any = [...searchList]
      if (chkSearchList[index][viewAs]) {
        if (chkSearchList[index][viewAs] !== selectedData) {
          cbValue = chkSearchList[index][viewAs]
          setSelectedData(chkSearchList[index][viewAs])
          if (chkSearchList[index].textLabel) {
            setSelectedDataText(chkSearchList[index].textLabel)
          }
          itemAction()
          onChange && onChange(chkSearchList[index])
        } else if (chkSearchList[index]?.allowMultipleSelects) {
          itemAction()
        }
      } else {
        if (chkSearchList[index].label !== selectedData) {
          setSelectedData(chkSearchList[index].label)
          if (chkSearchList[index].textLabel) {
            setSelectedDataText(chkSearchList[index].textLabel)
          }
          itemAction()
          onChange && onChange(chkSearchList[index])
        }
        else if (chkSearchList[index]?.allowMultipleSelects) {
          itemAction()
        }
      }
      setSearchText('')
    } else {
      if (list[index][viewAs] && list[index][viewAs] !== selectedData) {
        setSelectedData(list[index][viewAs])
        setSelectedDataText(list[index].textLabel)
        itemAction()
      }
      else {
        if (list[index].label !== selectedData && !list[index].value) {
          cbValue = list[index].label
          setSelectedData(list[index].label)
          if (list[index].textLabel) {
            setSelectedDataText(list[index].textLabel)
          }
          itemAction()
        }
        else if (list[index].value && list[index].value !== selectedData) {
          cbValue = list[index].value
          setSelectedData(list[index].value)
          itemAction()
        }
        else if (list[index]?.allowMultipleSelects) {
          itemAction()
        }
      }
      if (onChange !== undefined) {
        onChange(list[index])
      }
    }
    const { closeDropdownOnSelect } = itemObj || {}
    if (closeAfterSelect || closeDropdownOnSelect) {
      setIsComponentVisible(!isComponentVisible)
    }
  }

  const getDropDownMenuType = () => {
    if (dropDownType === 'secondary') {
      return 'Dropdown__menu--secondary'
    } else if (dropDownType === 'tertiary') {
      return 'Dropdown__menu--tertiary'
    } else if (dropDownType === 'quaternary') {
      return 'Dropdown__menu--quaternary'
    } else {
      return 'Dropdown__menu--primary'
    }
  }

  const getResults = () => {

    if (searchList.length > 0) {
      return searchList.map((item: any, i: any) => {
        const listItemActiveClass = item[viewAs] === selectedData
          ? 'Dropdown__menu__list__item--active'
          : '';
        if (item.actionTooltip) {
          return (
            <ActionTooltip {...item.actionTooltipProps}>
              <li
                key={i}
                className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${item[viewAs]
                  ? listItemActiveClass
                  : item.label === selectedData && 'Dropdown__menu__list__item--active'
                  }
              ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} `}
                onClick={(_evt) => {
                  if (!item.disable) handleSelect(item.action ? item.action : () => { /* TODO document why this arrow function is empty */ }, i, item)
                }}
                title={item.tooltip || ''}>
                {item.label}
              </li>
            </ActionTooltip>
          )
        }

        return (
          <li
            key={i}
            className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${item[viewAs]
              ? listItemActiveClass
              : item.label === selectedData && 'Dropdown__menu__list__item--active'
              }
              ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} `}
            onClick={(_evt) => {
              if (!item.disable) handleSelect(item.action ? item.action : () => { /* TODO document why this arrow function is empty */ }, i, item)
            }}
            title={item.tooltip || ''}>
            {item.label}
          </li>
        )
      })
    }
    return <div className="no-results">No Result Found</div>
  }
  const getDropdownListDom = () => {
    return (
      <div ref={withPortal ? ref : null}>
        {withSearch && (
          <div className="Dropdown__menu__searchBox">
            <Icon className="SearchIcon" icon="Search" size="original" />
            <TextInput
              placeholder={searchPlaceholder || 'Search here...'}
              type="search"
              value={searchText}
              onChange={handleChange}
              autoFocus></TextInput>
          </div>
        )}
        <ul
          className={`Dropdown__menu__list ${highlightActive ? 'Dropdown__menu__list--highlight-active' : ''}`}
          onScroll={onListScroll}>
          {searchText.length > 0
            ? getResults()
            : list.map((item: any, i: any) => {
              const dropDownActiveClass = item[viewAs] === selectedData
                ? 'Dropdown__menu__list__item--active'
                : '';
              if (item.actionTooltip) {
                return (
                  <ActionTooltip {...item.actionTooltipProps}>
                    <li
                      key={i}
                      className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''
                        } ${item[viewAs]
                          ? dropDownActiveClass
                          : item.label === selectedData && 'Dropdown__menu__list__item--active'
                        } 
              ${withIcon ? 'Dropdown__menu__list__item-icon' : ''}`}
                      onClick={(_evt) => {
                        if (!item.disable) handleSelect(item.action ? item.action : () => { /* TODO document why this arrow function is empty */ }, i, item)
                      }}
                      title={item.tooltip || ''}>
                      {item.label}
                    </li>
                  </ActionTooltip>
                )
              }

              return (
                <li
                  key={i}
                  className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''
                    } ${item[viewAs]
                      ? dropDownActiveClass
                      : item.label === selectedData && 'Dropdown__menu__list__item--active'
                    } 
              ${withIcon ? 'Dropdown__menu__list__item-icon' : ''}`}
                  onClick={(_evt) => {
                    if (!item.disable) handleSelect(item.action ? item.action : () => { /* TODO document why this arrow function is empty */ }, i, item)
                  }}
                  title={item.tooltip || ''}>
                  {item.label}
                </li>
              )
            })}
        </ul>
      </div>
    )
  }

  useEffect(() => {
    if (
      (!isComponentVisible && withPortal && portalId && document.getElementById(portalId)) ||
      (withPortal && portalId && !dropdownId && document.getElementById(portalId))
    ) {
      let ulObj: any = document.getElementById(portalId)
      ulObj.style.visibility = 'hidden'
      setDropdownId(true)
    }
    if (isComponentVisible && withPortal && portalId && document.getElementById(portalId)) {
      const key = headerPortalId ? headerPortalId : dropdownKey;
      const headerObj: any = withPortal
        ? document
          .getElementById(`cs-dropdown-${key}`)
          .getBoundingClientRect()
        : ref.current.getBoundingClientRect()
      let ulObj: any = document.getElementById(portalId)
      ulObj.style.visibility = 'visible'
      ulObj.style.position = 'absolute'
      ulObj.style.zIndex = 10
      ulObj.style.top = `${headerObj.top + 25}px`
      ulObj.style.left = `${headerObj.left - 20}px`
    }
  }, [dropdownId, isComponentVisible])

  useEffect(() => {
    setSelectedData(getDefaultValue(list))
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  useEffect(() => {
    if (searchText.length === 0) {
      setSearchList([])
    }
  }, [searchText])

  const getEmptyPlaceholder = () => {
    return (<>
      {!selectedData && emptyPlaceholder ? (
        <div className="Dropdown__header__value">{emptyPlaceholder}</div>
      ) : (
        <div className="Dropdown__header__value" title={selectedDataText || selectedData}>
          {selectedData || searchPlaceholder}
        </div>
      )}
    </>)
  }
  const ChevronIcon = () => {
    return (
      <Icon
        className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
        icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
        size="original"
      />
    )
  }

  return (
    <div
      data-test-id={testId}
      ref={!withPortal ? ref : headerRef}
      className={cn(
        `Dropdown--hover ${classNames}`,
        {
          'disable__dropdown': isDisabled,
          'Dropdown--open': isComponentVisible
        }
      )}
      id={`cs-dropdown-${headerPortalId ? headerPortalId : dropdownKey}`}>
      <div
        ref={withPortal ? headerEleRef : null}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        className={`Dropdown__header ${dropDownType === 'tertiary' ? 'flex-justify' : ''} ${isComponentVisible ? 'Dropdown__header--max' : 'Dropdown__header--min'
          }`}>
        {headerLabel ? (
          <div>
            {headerLabel && (
              <div className="Dropdown__header__label">{headerLabel}</div>
            )}
            <div className='flex-v-center'>
              <div className="Dropdown__header__value" title={selectedDataText || selectedData}>
                {selectedData}
              </div>
              <ChevronIcon />
            </div>
          </div>) :
          <>
            {getEmptyPlaceholder()}
            {!headerLabel && <ChevronIcon />}
          </>}
      </div >
      {isComponentVisible && (
        <div
          className={getDropDownMenuType()}
          style={{
            ...(adjustWidthForContent && { width: 'min-content' }),
            ...(maxWidth && { maxWidth: maxWidth, minWidth: 0 })
          }}>
          {dropDownType === 'tertiary' && (
            <div
              className="Dropdown__menu__header flex-justify"
              onClick={() => {
                setIsComponentVisible(!isComponentVisible)
              }}>
              <div className="Dropdown__menu__title">{title}</div>
              <Icon
                className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
                icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
                size="original"
              />
            </div>
          )}
          {dropdownId && withPortal && portalId ? (
            <SearchPortal domId={portalId}>
              <div className={getDropDownMenuType()}>{getDropdownListDom()}</div>
            </SearchPortal>
          ) : (
            getDropdownListDom()
          )}
        </div>
      )}
    </div >
  )
}

const getDropDown = ({
  list,
  children,
  type,
  withSearch,
  dropDownPosition,
  withArrow,
  arrowSecondary,
  className,
  dropDownType,
  withIcon,
  title,
  emptyPlaceholder,
  searchPlaceholder,
  closeAfterSelect,
  isEllipse,
  isMultiCheck,
  onChange,
  viewAs,
  highlightActive,
  headerLabel,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
  testId,
  withPortal,
  portalId,
  headerPortalId,
  ellipseAlignment,
  dragDropProps,
  isDisabled
}: IDropdown) => {
  const TypeClass = `Dropdown--${dropDownType}`
  const Position = `Dropdown--position-${dropDownPosition}`
  const classNames = cn('Dropdown', [TypeClass], [Position], className)

  switch (type) {
    case 'click': {
      return (
        <ClickableDropDown
          list={list}
          children={children}
          withArrow={withArrow}
          withIcon={withIcon}
          arrowSecondary={arrowSecondary}
          classNames={classNames}
          closeAfterSelect={closeAfterSelect}
          isEllipse={isEllipse}
          isMultiCheck={isMultiCheck}
          title={title}
          adjustWidthForContent={adjustWidthForContent}
          maxWidth={maxWidth}
          onListScroll={onListScroll}
          testId={testId}
          viewAs={viewAs}
          onChange={onChange}
          highlightActive={highlightActive}
          ellipseAlignment={ellipseAlignment}
          dragDropProps={dragDropProps}
          isDisabled={isDisabled}
        />
      )
    }
    case 'hover': {
      return (
        <HoverDropDown
          list={list}
          children={children}
          withArrow={withArrow}
          withIcon={withIcon}
          arrowSecondary={arrowSecondary}
          classNames={classNames}
          closeAfterSelect={closeAfterSelect}
          isEllipse={isEllipse}
          adjustWidthForContent={adjustWidthForContent}
          maxWidth={maxWidth}
          onListScroll={onListScroll}
          testId={testId}
          isDisabled={isDisabled}
        />
      )
    }
    case 'select': {
      return (
        <SelectDropDown
          list={list}
          withSearch={withSearch}
          arrowSecondary={arrowSecondary}
          classNames={classNames}
          withIcon={withIcon}
          dropDownType={dropDownType}
          title={title}
          searchPlaceholder={searchPlaceholder}
          closeAfterSelect={closeAfterSelect}
          onChange={onChange}
          viewAs={viewAs}
          highlightActive={highlightActive}
          headerLabel={headerLabel}
          emptyPlaceholder={emptyPlaceholder}
          adjustWidthForContent={adjustWidthForContent}
          maxWidth={maxWidth}
          onListScroll={onListScroll}
          testId={testId}
          withPortal={withPortal}
          portalId={portalId}
          headerPortalId={headerPortalId}
          isDisabled={isDisabled}
        />
      )
    }
  }
}

const Dropdown: React.FunctionComponent<IDropdown> = (props) => {
  const {
    list,
    children,
    type,
    withSearch,
    dropDownPosition,
    withArrow,
    withIcon,
    arrowSecondary,
    className,
    dropDownType,
    title,
    searchPlaceholder,
    emptyPlaceholder,
    closeAfterSelect,
    isEllipse,
    isMultiCheck,
    onChange,
    viewAs,
    highlightActive,
    headerLabel,
    adjustWidthForContent,
    maxWidth,
    onListScroll,
    testId,
    withPortal,
    portalId,
    headerPortalId,
    ellipseAlignment,
    dragDropProps = {},
    isDisabled
  } = props

  return (
    <>
      {getDropDown({
        list,
        children,
        type,
        withSearch,
        dropDownPosition,
        withArrow,
        withIcon,
        arrowSecondary,
        className,
        dropDownType,
        title,
        searchPlaceholder,
        closeAfterSelect,
        isEllipse,
        isMultiCheck,
        onChange,
        viewAs,
        highlightActive,
        headerLabel,
        emptyPlaceholder,
        adjustWidthForContent,
        maxWidth,

        onListScroll,
        testId,
        withPortal,
        portalId,
        headerPortalId,
        ellipseAlignment,
        dragDropProps,
        isDisabled
      })}
    </>
  )
}

Dropdown.defaultProps = {
  dropDownPosition: 'bottom',
  withSearch: false,
  withArrow: false,
  withIcon: false,
  dropDownType: 'primary',
  closeAfterSelect: false,
  isEllipse: false,
  isMultiCheck: false,
  arrowSecondary: false,
  viewAs: 'label',
  highlightActive: false,
  adjustWidthForContent: false,
  testId: 'cs-dropdown',
  isDisabled: false
} as Partial<IDropdown>

export default Dropdown
